<template>
  <div class="sleep-screen" @click="goToMainScreen()">
    <video
      playsinline
      autoplay
      muted
      :loop="videos.length === 1"
      id="video"
      v-if="showVideo"
      :src="videos[active]"
      @ended="videoHandler"
    />

    <div class="button">
      <div class="start-button">
        <div class="start-button-text">
          Коснитесь
          <br />для старта
        </div>
        <div class="start-button__circles">
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // poster: "/videos/poster.jpg",
      videos: ["/videos/1.mp4"],
      active: 0,
      showVideo: true
    };
  },

  methods: {
    videoHandler() {
      this.active =
        this.active !== this.videos.length - 1 ? this.active + 1 : 0;
    },
    goToMainScreen() {
      this.$router.push({ path: "/home" });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/scss/utils/_variables.scss";

.sleep-screen {
  position: relative;
  height: 1360px;
  background: $color_grey_2;
  overflow: hidden;
  &:before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    background-color: rgba(#000, 0.4);
    z-index: 2;
    width: 100%;
    height: 100%;
  }

  #video {
    position: absolute;
    top: 50%;
    left: 50%;
    min-width: 100%;
    max-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    transform: translateX(-50%) translateY(-50%);
    background-size: cover;
  }

  .button {
    position: absolute;
    top: 762px;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 288px;
    height: 288px;
    border: solid 4px rgba(255, 255, 255, 0.8);
    background: rgba(255, 255, 255, 0.4);
    border-radius: 50%;
    z-index: 2;
  }
  .start-button {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-align-content: center;
    -ms-flex-line-pack: center;
    align-content: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    height: 100%;
  }

  .start-button-text {
    text-align: center;
    font-family: $ObjectSans;
    font-size: 38px;
    font-weight: 500;
  }
  .start-button__circles {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
  }
  .start-button__circles span {
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 100%;
    height: 100%;
    border: solid 4px rgba(255, 255, 255, 0.8);
    border-radius: 50%;
    opacity: 0;
    transform: scale(1);
  }
  .start-button__circles span:nth-child(1) {
    animation: pulse-1 2s infinite ease-in;
  }
  @keyframes pulse-1 {
    0% {
      transform: scale(1);
      opacity: 0;
    }
    20% {
      opacity: 1;
    }
    100% {
      transform: scale(1.5);
      opacity: 0;
    }
  }
  .start-button__circles span:nth-child(2) {
    animation: pulse-1 2s infinite ease-in;
    animation-delay: 1s;
  }
  @keyframes pulse-1 {
    0% {
      transform: scale(1);
      opacity: 0;
    }
    20% {
      opacity: 1;
    }
    100% {
      transform: scale(1.5);
      opacity: 0;
    }
  }
}
</style>